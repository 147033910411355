import React, { Component } from 'react'
import Breadcrumb from '../components/public/breadcrumb';
import LoadingPage from '../components/public/loadingpage';
import { instance } from '../modules/instance';
import blankImage from '../assets/images/blank.jpg'
export class ShowRooms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRooms: [],
            isLoading: true,
        };
    }
    getShowRooms() {
        instance
            .get("/show-rooms")
            .then((res) => {
                this.setState({ showRooms: res.data });
            })
            .catch((err) => {
                alert(err);
                window.location.href = "/";
            })
            .then(() => {
                this.setState({ isLoading: false });
            });
    }
    getExt(str) {

        return str.split('.')[str.split('.').length - 1]
    }
    componentDidMount() {
        this.getShowRooms();
    }
    render() {
        return this.state.isLoading ? (
            <>
                <LoadingPage />
            </>
        ) : (
            <>
                <Breadcrumb title='صالة العرض' />
                <section className='ftco-section bg-light'>
                    <div className='container-xl'>
                        <div className='row ' dir='rtl'>
                            {this.state.showRooms.map((showRoom) => (
                                <div className="col-12 my-2" key={showRoom.id}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div class="container gallery-container">

                                                <h1>{showRoom.title}</h1>
                                                <p class="page-description text-center">{showRoom.description}</p>
                                                <div class="tz-gallery">

                                                    <div class="row">
                                                        {showRoom.images.map((image) =>

                                                            (['jpg', 'svg', 'jpeg', 'gif', 'png', 'bin'].includes(this.getExt(image.image))) ? (
                                                                <div class="col-sm-12 col-md-4" key={image.id}>
                                                                    <a target="_blank" class="lightbox" href={image.image}>
                                                                        <img src={image.image} alt={showRoom.title} />
                                                                    </a>
                                                                </div>
                                                            ) : (
                                                                (
                                                                    <div class="col-sm-12 col-md-4" key={image.id}>
                                                                        <a target="_blank" class="lightbox" href={image.image}>
                                                                            <video controls poster={blankImage}>
                                                                                <source src={image.image} />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            )
                                                        )}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ShowRooms

/*

   {(this.getExt(image.image) == 'mp4') ? (
                                                            <div class="col-sm-12 col-md-4" key={image.id}>
                                                               <video controls poster="/images/w3html5.gif">
                                                                    <source src="movie.mp4" type="video/mp4"/>
                                                                    <source src="movie.ogg" type="video/ogg"/>
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </div>
                                                        ) : (
                                                            <div class="col-sm-12 col-md-4" key={image.id}>
                                                                <a target="_blank" class="lightbox" href={image.image}>
                                                                    <img src={image.image} alt={showRoom.title} />
                                                                </a>
                                                            </div>
                                                        )}
*/